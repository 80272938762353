import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CategoryCard from '../components/CategoryCard';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './HomePage.css';
import './CategoryPage.css';
import BottomArrow from "../components/BottomArrow";
import UnderHeader from "../components/UnderHeader";

const CategoryPage = () => {
    const { shopId } = useParams(); // Получаем shopId из URL
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Фетчинг категорий для конкретного магазина
        fetch(`https://leviatyan.online/categories/inshop/${shopId}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setCategories(data || []);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
                setLoading(false);
            });
    }, [shopId]);

    if (loading) {
        return (
          <div className="loading-container">
            <div className="spinner"></div>
            <p>Loading, please wait...</p>
          </div>
        );
      }      

    if (categories.length === 0) {
        return <div className="not-available">No categories available for this shop.</div>;
    }

    return (
        <div className="category-page">
            <div className="headers">
                <Header/>
                <UnderHeader></UnderHeader>
            </div>
            <div className="categories-container">
                <h1>Categories</h1>
                <div className="category-cards">
                    {categories.map((category) => (
                        <CategoryCard
                            key={category.id}
                            image={category.picture}
                            title={category.name}
                            description={category.description || ''}
                            link={`/shops/${shopId}/categories/${category.id}/products`}
                        />
                    ))}
                </div>
            </div>
            <Footer />
            <BottomArrow />
        </div>
    );
};

export default CategoryPage;
