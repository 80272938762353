export const fetchAddress = async (setAddress) => {
    try {
        const response = await fetch('https://leviatyan.online/delivery/get-address', {
            method: 'GET',
            credentials: 'include',
        });
        if (response.ok) {
            const data = await response.json();
            setAddress(data); // Directly set address from response
        } else {
            console.error('Failed to fetch address:', response.statusText);
        }
    } catch (error) {
        console.error('Error fetching address:', error);
    }
};

export const saveAddress = async (address) => {
    try {
        const response = await fetch('https://leviatyan.online/delivery/save-address', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(address),
        });
        if (response.ok) {
            alert("Address saved successfully.");
        } else {
            console.error('Failed to save address:', response.statusText);
        }
    } catch (error) {
        console.error('Error saving address:', error);
    }
};
