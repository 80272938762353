// src/utils/cartUtils.js
const API_CART_URL = 'https://leviatyan.online/cart';

export const addToCart = async (productId, quantity = 1, updateCartQuantity) => {
    try {
      const authResponse = await fetch('/api/user', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!authResponse.ok) {
        alert('Вы не авторизованы. Пожалуйста, войдите в систему.');
        return;
      }
  
      const response = await fetch(`${API_CART_URL}/add`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productId: productId,
          quantity: quantity,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error adding item to cart');
      }
  
      const data = await response.json();

      // Обновляем количество товаров в контексте
      if (updateCartQuantity) {
        const totalQuantity = await fetchCartQuantity();
        updateCartQuantity(totalQuantity);
      }

      return data;
    } catch (error) {
      console.error('Error adding item to cart:', error);
      throw error;
    }
  };

  export const fetchCartQuantity = async () => {
    try {
      const response = await fetch(`${API_CART_URL}/view`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Error fetching cart data');
      }
  
      const data = await response.json();
      const totalQuantity = data.items
        ? data.items.reduce((sum, item) => sum + item.quantityInCart, 0)
        : 0;
  
      return totalQuantity;
    } catch (error) {
      console.error('Error fetching cart quantity:', error);
      return 0; // Возвращаем 0 в случае ошибки
    }
  };  
  
  export const updateCartItemQuantity = async (productId, increment, updateCartQuantity) => {
    try {
      const response = await fetch(`${API_CART_URL}/update-quantity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ productId, increment }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error adding item to cart');
      }
  
      const data = await response.json();
      console.log('Quantity updated:', data);

      // Обновляем количество товаров в контексте
      if (updateCartQuantity) {
        const totalQuantity = await fetchCartQuantity();
        updateCartQuantity(totalQuantity);
      }

      return data.items;
    } catch (error) {
      console.error('Error updating quantity:', error);
      throw error;
    }
  };
  
  export const removeItemFromCart = async (productId, updateCartQuantity) => {
    try {
      const response = await fetch(`${API_CART_URL}/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ productId }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Item removed:', data);

      // Обновляем количество товаров в контексте
      if (updateCartQuantity) {
        const totalQuantity = await fetchCartQuantity();
        updateCartQuantity(totalQuantity);
      }

      return data.items;
    } catch (error) {
      console.error('Error removing item:', error);
      throw error;
    }
  };