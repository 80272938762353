// src/utils/marketUtils.js
const API_PRODUCTS_URL = 'https://leviatyan.online/products';
const API_CATEGORIES_URL = 'https://leviatyan.online/categories';
const API_SHOPS_URL = 'https://leviatyan.online/shops';

// Getting all shops, categories and products
export const fetchShops = async () => {
  const response = await fetch(`${API_SHOPS_URL}/get`, { credentials: 'include' });
  if (!response.ok) throw new Error('Failed to fetch shops');
  return response.json();
};

export const fetchActiveShops = async () => {
  const response = await fetch(`${API_SHOPS_URL}/get-active`, { credentials: 'include' });
  if (!response.ok) throw new Error('Failed to fetch active shops');
  return response.json();
};

export const fetchCategories = async () => {
  const response = await fetch(`${API_CATEGORIES_URL}/get`, { credentials: 'include' });
  if (!response.ok) throw new Error('Failed to fetch categories');
  return response.json();
};

export const fetchShopCategories = async (shopId) => {
  const response = await fetch(`${API_CATEGORIES_URL}/inshop/${shopId}`, { credentials: 'include' });
  if (!response.ok) throw new Error('Failed to fetch categories');
  return response.json();
};

export const fetchProducts = async () => {
  const response = await fetch(`${API_PRODUCTS_URL}/get`, { credentials: 'include' });
  if (!response.ok) throw new Error('Failed to fetch products');
  return response.json();
};

// Adding all shops, categories and products
export const addShop = async (shopData) => {
  const response = await fetch(`${API_SHOPS_URL}/add`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(shopData),
    credentials: 'include',
  });
  if (!response.ok) throw new Error('Failed to add shop');
  return response.json();
};

export const addCategory = async (shopId, categoryData) => {
  const response = await fetch(`${API_CATEGORIES_URL}/add`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ shopId, ...categoryData }),
    credentials: 'include',
  });
  if (!response.ok) throw new Error('Failed to add category');
  return response.json();
};

export const addProduct = async (categoryId, productData) => {
  const response = await fetch(`${API_PRODUCTS_URL}/add`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ categoryId, ...productData }),
    credentials: 'include',
  });
  if (!response.ok) throw new Error('Failed to add product');
  return response.json();
};

// Updating shop status for disabling
export const updateShopStatus = async (shopId, newStatus) => {
  const response = await fetch(`${API_SHOPS_URL}/update-status/${shopId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ status: newStatus }),
    credentials: 'include',
  });

  if (!response.ok) throw new Error('Failed to update shop status');
  return response.json();
};
