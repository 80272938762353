// src/components/Footer.js
import React, { useEffect, useRef, useState } from 'react';
import './BottomArrow.css';

const BottomArrow = () => {
    const scrollTopBtnRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button
            ref={scrollTopBtnRef}
            id="scrollTopBtn"
            className={isVisible ? 'show' : ''}
            onClick={scrollToTop}
            title="Go to top"
        >
            ↑
        </button>
    );
};

export default BottomArrow;
