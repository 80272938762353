// src/pages/HomePage.js
import React, { useState, useEffect } from 'react';
import ShopCard from '../components/ShopCard';
import Header from '../components/Header';
import './HomePage.css';
import Footer from '../components/Footer';
import BottomArrow from "../components/BottomArrow";
import { fetchActiveShops } from '../utils/marketUtils';

const HomePage = () => {
    const [shops, setShops] = useState([]);

    const fetchShops = () => {
        fetchActiveShops()
            .then(data => setShops(data || []))
            .catch(error => console.error('Error fetching shops:', error));
    };

    useEffect(() => {
        fetchShops();
    }, []);

    const shopsSectionElement = document.querySelector('.shops-section');
    const shopsSectionButtonElement = document.querySelector('.cta-button');

    const scrollToElement = () => {
        if (shopsSectionElement) {
            shopsSectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    if (shopsSectionButtonElement) {
        shopsSectionButtonElement.addEventListener('click', scrollToElement);
    }


    return (
        <div className="home-page">
            <Header />
            <div className="hero-section">
                <div className="hero-content">
                    <h1>Welcome to Artisan's Heaven</h1>
                    <h2>Discover Unique Handmade Creations from Talented Artists</h2>
                    <button className="cta-button">Shop Now</button>
                </div>
                <img src="/img/banner_main.jpg" alt="Artisan-crafted products on display" className="hero-image" />
            </div>

            {/* <div className="featured-section" id="cards-anchor">
                <h3>Featured Collections</h3>
                <div className="cards-container">
                    <ShopCard image="/img/main_wooden_goods.jpg"
                        alt="Wooden cutting board"
                        title="Rustic Woodwork"
                        link="/art" />
                </div>
            </div> */}
            <div className="shops-section" id="shops-section">
                <h3>Explore Shops</h3>
                <div className="shops-container">
                    {shops.length > 0 ? (
                        shops.map((shop) => (
                            <ShopCard
                                key={shop.id}
                                image={shop.picture}
                                title={shop.name}
                                description={shop.description}
                                ownerName={shop.ownerName} // исправлено
                                ownerEmail={shop.ownerEmail} // исправлено
                                ownerPhone={shop.ownerPhone}
                                link={`https://leviatyan.online/shops/${shop.id}/categories`} // link to front
                            />

                        ))
                    ) : (
                        <p>No shops available at the moment.</p>
                    )}
                </div>
            </div>

            <div className="about-section">
                <h3>Why Choose Artisan's Heaven?</h3>
                <p>
                    Every piece is crafted with love and attention to detail. When you shop here, you support independent artists and sustainable practices.
                    Let's create a world full of creativity and beauty.
                </p>
                <img src="/img/main_artist_working.jpg" alt="Artisan at work" className="about-image" />
                
            </div>
            <div>
            <a href="https://freekassa.com" target="_blank" rel="noopener noreferrer">
  <img src="https://cdn.freekassa.com/banners/big-dark-1.png" title="Прием платежей"></img>
</a>
            </div>
            <Footer />
            <BottomArrow />
        </div>
    );
};

// const HomePage = () => {
//   return (
//     <div className="home-page">
//       <Header />
//       <div className='content'>
//       <h1>ArtPlace</h1>
//       <h2>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</h2>
//       </div>

//       <div className="game-cards-container">
//       <GameCard image="/img/paintings_card.jpg" title="HandMade" link="/rust" />
//       <GameCard image="/img/paintings_card.jpg" alt="paintings" title="ART" link="/art" />
//       </div>
//       <Footer/>
//       <div>
//         {/* <a href="https://freekassa.com" target="_blank" rel="noopener noreferrer">
//           <img src="https://cdn.freekassa.com/banners/big-dark-1.png" title="Прием платежей"></img>
//         </a> */}
//       </div>
//     </div>
//   );
// };

export default HomePage;