import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './CartPage.css';
import UnderHeader from '../components/UnderHeader';
import BottomArrow from "../components/BottomArrow";

const ProductsInCategoryPage = () => {
    const { shopId, categoryId } = useParams(); // Получаем shopId и categoryId из URL
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    function CommaFormatted(amount) {
        if (typeof amount !== 'string') {
            amount = amount.toFixed(2);
        }

        const delimiter = ",";
        const [integerPart, decimalPart = ''] = amount.split('.');

        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

        return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    }

    useEffect(() => {
        // Фетчинг продуктов для конкретной категории и магазина
        fetch(`https://leviatyan.online/products/incategory/${categoryId}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setProducts(data || []);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching products:', error);
                setLoading(false);
            });
    }, [shopId, categoryId]);

    if (loading) {
        return (
          <div className="loading-container">
            <div className="spinner"></div>
            <p>Loading, please wait...</p>
          </div>
        );
      }      

    if (products.length === 0) {
        return <div className="not-available">No products available in this category.</div>;
    }

    return (
        <div className="products-page">
            <div className="headers">
                <Header/>
                <UnderHeader/>
            </div>
            <div className="products-container">
                <h1>Products</h1>
                <div className="product-cards">
                    {products.map((product) => (
                        <ProductCard
                        key={product.id}
                        productId={product.id}
                        name={product.name}
                        description={product.description}
                        price={CommaFormatted(product.price_czk.toString())}
                        stock={product.stock} // Убедитесь, что stock передается
                        image={product.picture}
                    />                    
                    ))}
                </div>
            </div>
            <Footer />
            <BottomArrow />
        </div>
    );
};

export default ProductsInCategoryPage;
