// src/components/ServiceCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './CategoryCard.css';

const CategoryCard = ({ image, title, description, link }) => {
  return (
    <div className="category-card">
      <Link to={link}>
        <img src={image} alt={title} className="category-image" />
        <div className="category-title">{title}</div>
        <div className="category-description">{description}</div>
      
        <button className="info-button">
          More info
        </button>
        </Link>
      </div>
  );
};

export default CategoryCard;
