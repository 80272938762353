import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const NewUsersChart = ({ data }) => {
    if (!data || Object.keys(data).length === 0) {
        return <p>No user data available to render the chart.</p>;
    }

    const chartData = {
        labels: Object.keys(data),
        datasets: [
            {
                label: 'New Users',
                data: Object.values(data),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Days of the Month',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Users',
                },
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="chart">
            <h2>New Users Over Time</h2>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default NewUsersChart;
