import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UnderHeader.css';

const UnderHeader = ({ title }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
      <div className="under-header">
          <div className="back-button-block">
            <button className="back-button" onClick={handleBackClick}>
              Back
            </button>
          </div>
        <div className="under-title">
          <h1>{title}</h1>
        </div>
      </div>
  );
};

export default UnderHeader;
