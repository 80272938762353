// src/components/ProductCard.js
import React, { useContext, useState } from 'react';
import './ProductCard.css';
import { addToCart } from '../utils/cartUtils';
import { CartContext } from '../context/CartContext'; // Подключаем CartContext

const ProductCard = ({ productId, name, description, price, stock, image, categoryId }) => {
  const { updateCartQuantity } = useContext(CartContext); // Получаем updateCartQuantity из контекста
  const [buttonText, setButtonText] = useState('Add to Cart'); // Состояние для текста кнопки
  const [localStock, setLocalStock] = useState(stock);
  const [isAdding, setIsAdding] = useState(false); // Флаг для блокировки кнопки

  const handleAddToCart = async () => {
    if (isAdding) return; // Если кнопка уже заблокирована, ничего не делаем
    setIsAdding(true); // Блокируем кнопку

    try {
      console.log('Attempting to add to cart...');
      if (stock < 1) {
        console.log('Out of stock:', localStock);
        throw new Error(`Product "${name}" is out of stock.`);
      }

      const data = await addToCart(productId, 1, updateCartQuantity);
      if (data) {
        // Обновляем stock локально
        setLocalStock((prevStock) => prevStock - 1);
        // Визуальное уведомление об успешном добавлении
        setButtonText('Added!');
        setTimeout(() => setButtonText('Add to Cart'), 1000); // Возвращаем текст через 2 секунды
      }
    } catch (error) {
      console.error('Ошибка при добавлении товара:', error);
      alert(error.message);
    } finally {
      setIsAdding(false); // Разблокируем кнопку
    }
  };

  return (
    <div className="product-card">
      <div className="product-details">
        <p className="product-title">{name}</p>
        <img src={image} alt={name} className="product-image" />
        <p className="product-description">{description}</p>
        <div className="product-footer">
          <span className="product-price">{price} CZK</span>
          <button
            className={`add-to-cart-button ${localStock < 1 ? 'disabled-button' : ''}`}
            onClick={handleAddToCart}
            disabled={localStock < 1 || isAdding} // Блокируем кнопку, если товара нет или выполняется запрос
          >
            {localStock < 1 ? 'Out of Stock' : buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
