import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const StoreSalesChart = ({ data }) => {
    if (!data || Object.keys(data).length === 0) {
        return <p>No sales data available to render the chart.</p>;
    }

    const chartData = {
        labels: Object.keys(data),
        datasets: [
            {
                label: 'Sales by Store',
                data: Object.values(data),
                backgroundColor: Object.keys(data).map(
                    (_, index) => `hsl(${(index * 360) / Object.keys(data).length}, 70%, 50%)`
                ),
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Stores',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Sales',
                },
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="chart">
            <h2>Sales by Store</h2>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default StoreSalesChart;
