// src/pages/ProfilePage.js
import React, { useEffect, useState } from 'react';
import { fetchShops, fetchCategories, fetchProducts, addShop, addCategory, addProduct, updateShopStatus, fetchShopCategories } from '../utils/marketUtils';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './ProfilePage.css';
import BottomArrow from "../components/BottomArrow";
import UnderHeader from "../components/UnderHeader";
import RevenueChart from '../components/charts/RevenueChart';
import NewUsersChart from '../components/charts/NewUsersChart';
import StoreSalesChart from '../components/charts/StoreSalesChart';

const ProfilePage = () => {
  const [user, setUser] = useState(null); // Current logged-in user
  const [purchases, setPurchases] = useState([]); // Purchases for regular users
  const [activeTab, setActiveTab] = useState('Products'); // Default tab for admin profile
  const [activeAddTab, setActiveAddTab] = useState('AddShop'); // Default tab for adding components
  const [shops, setShops] = useState([]); // Products Tab
  const [categories, setCategories] = useState([]);
  const [selectedShop, setSelectedShop] = useState(''); // ID выбранного магазина
  const [categoriesInShop, setCategoriesInShop] = useState([]);
  const [products, setProducts] = useState([]);
  const [newShop, setNewShop] = useState({
    name: '',
    description: '',
    ico: '',
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    picture: '',
    status: 'active',
  });
  const [newCategory, setNewCategory] = useState({
    name: '',
    shopId: '',
    picture: '',
  });
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    price_czk: '',
    stock: '',
    picture: '',
    categoryId: '',
  });
  const [orders, setOrders] = useState([]); // Orders Tab
  const [allUsers, setAllUsers] = useState([]); // All users for Users Tab
  const [statistics, setStatistics] = useState({
    revenueByDay: {},
    usersByDay: {},
    salesByStore: {},
    totalPurchases: 0,
    totalRevenue: 0
  });
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


  function CommaFormatted(amount) {
    if (typeof amount !== 'string') {
      amount = amount.toFixed(2);
    }

    const delimiter = ",";
    const [integerPart, decimalPart = ''] = amount.split('.');

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  }

  const downloadCSV = (orders) => {
    const csvRows = [];
    const today = new Date().toISOString().split('T')[0]; // Текущая дата в формате YYYY-MM-DD

    // Добавление данных о заказах в начале файла
    csvRows.push('Order ID;Date;Status;Address;User Email;Products;Total');

    orders.forEach((order) => {
        const products = order.items
            .map((item) => `${item.Product?.name || 'Unknown Product'} (${item.quantity} x ${item.price})`)
            .join(', ');

        csvRows.push(
            `${order.id};${new Date(order.createdAt).toLocaleDateString()};${order.status};"${order.deliveryAddress}";` +
            `"${order.User.email}";"${products}";${order.total}`
        );
    });

    // Добавление разделителя перед статистикой
    csvRows.push('');
    csvRows.push('Date;Revenue;New Users');

    // Объединение данных `revenueByDay` и `usersByDay`
    const allDates = new Set([
        ...Object.keys(statistics.revenueByDay),
        ...Object.keys(statistics.usersByDay),
    ]);

    Array.from(allDates).forEach((date) => {
        const revenue = statistics.revenueByDay[date] || 0;
        const newUsers = statistics.usersByDay[date] || 0;
        csvRows.push(`${date};${revenue};${newUsers}`);
    });

    // Добавление данных `salesByStore`
    csvRows.push('');
    csvRows.push('Store;Sales');
    Object.keys(statistics.salesByStore).forEach((store) => {
        csvRows.push(`${store};${statistics.salesByStore[store]}`);
    });

    // Общие данные
    csvRows.push('');
    csvRows.push(`Total Revenue;${statistics.totalRevenue}`);
    csvRows.push(`Total Purchases;${statistics.totalPurchases}`);

    // Преобразование в строку и скачивание
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    // Название файла с годом, месяцем и текущей датой
    a.download = `Statistics_${today}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    fetch('https://leviatyan.online/api/user', { credentials: 'include' })
      .then(res => {
        if (!res.ok) throw new Error('Failed to fetch user data');
        return res.json();
      })
      .then(userData => { //admin/user info return here
        setUser(userData);
        if (userData && userData.isAdmin) {
          fetchAdminData();
        } else if (userData) {
          fetch(`https://leviatyan.online/orders/user/${userData.id}`, { credentials: 'include' })
            .then(res => {
              if (!res.ok) throw new Error('Failed to fetch user orders');
              return res.json();
            })
            .then(data => setPurchases(data.orders || []));
        }
        // return Promise.resolve({ json: () => ({}) }); // Fallback for no data
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const fetchAdminData = () => {
    // Запрос магазинов, категорий и продуктов
    fetchShops()
      .then(data => setShops(data))
      .catch(error => console.error('Error fetching shops:', error));

    fetchCategories()
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));

    fetchProducts()
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching products:', error));
    
    fetchStatistics();

    // Запрос всех заказов
    fetch('https://leviatyan.online/orders/get', { credentials: 'include' })
      .then(res => res.json())
      .then(data => setOrders(data))
      .catch(error => console.error('Error fetching orders:', error));

    // Запрос всех пользователей
    fetch('https://leviatyan.online/auth/all', { credentials: 'include' })
      .then(res => res.json())
      .then(data => setAllUsers(data))
      .catch(error => console.error('Error fetching users:', error));

  };

  const handleChangeShopStatus = (shopId, newStatus) => {
    updateShopStatus(shopId, newStatus)
      .then((updatedShop) => {
        alert(`Shop status updated to "${updatedShop.status}"`);
        setShops((prevShops) =>
          prevShops.map((shop) =>
            shop.id === shopId ? { ...shop, status: updatedShop.status } : shop
          )
        );
      })
      .catch((error) => {
        console.error('Error updating shop status:', error);
        alert('Failed to update shop status.');
      });
  };

  const handleAddShop = () => {
    // Validation section
    if (!newShop.name || !newShop.description || !newShop.ico || !newShop.ownerName || !newShop.ownerEmail || !newShop.ownerPhone) {
      alert('Please fill in all required fields for the shop.');
      return;
    }

    // Validate ICO (must be exactly 8 digits)
    if (!/^\d{8}$/.test(newShop.ico)) {
      alert('ICO must be exactly 8 digits.');
      return;
    }
    // Validate Owner Email (basic email regex)
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newShop.ownerEmail)) {
      alert('Please enter a valid email address.');
      return;
    }
    // Validate Owner Phone (basic phone number regex)
    if (!/^\+?[0-9]{7,15}$/.test(newShop.ownerPhone)) {
      alert('Please enter a valid phone number.');
      return;
    }
    // Validate Picture URL (if provided, must be a valid URL)
    if (newShop.picture && !/^https?:\/\/[\w-]+(\.[\w-]+)+[/#?]?.*$/.test(newShop.picture)) {
      alert('Please provide a valid URL for the picture.');
      return;
    }
    // Validate Status (must be either "active" or "disabled")
    if (!['active', 'disabled'].includes(newShop.status)) {
      alert('Status must be either "active" or "disabled".');
      return;
    }

    if (!newShop.picture) {
      newShop.picture = null; // Применить значение по умолчанию из модели
    }

    if (!newShop.status) {
      newShop.status = 'active'; // Применить значение по умолчанию из модели
    }

    addShop(newShop)
      .then((addedShop) => {
        alert(`Shop "${addedShop.name}" added successfully!`);
        setShops(prev => [...prev, addedShop]);
        setNewShop({
          name: '',
          description: '',
          ico: '',
          ownerName: '',
          ownerEmail: '',
          ownerPhone: '',
          picture: '',
          status: '',
        });
      })
      .catch(async (error) => {
        console.error('Error adding shop:', error);
        if (error.response && error.response.data && error.response.data.message) {
          alert(`Failed to add shop: ${error.response.data.message}`);
        } else {
          alert('Failed to add shop: Unknown error occurred.');
        }
      });
  };

  const handleAddCategory = () => {
    if (!newCategory.name) {
      alert('Please fill in all the fields for the category.');
      return;
    }
    // Ensure selected shopId exists in the list of shops
    const selectedShop = shops.find((shop) => shop.id === newCategory.shopId);
    if (!selectedShop) {
      alert('Please select a valid shop from the list.');
      return;
    }
    // Validate Picture URL (if provided, must be a valid URL)
    if (newCategory.picture && !/^https?:\/\/[\w-]+(\.[\w-]+)+[/#?]?.*$/.test(newCategory.picture)) {
      alert('Please provide a valid URL for the picture.');
      return;
    }

    if (!newCategory.picture) {
      newCategory.picture = null; // Применить значение по умолчанию из модели
    }

    addCategory(newCategory.shopId, { name: newCategory.name, picture: newCategory.picture })
      .then((addedCategory) => {
        alert(`Category "${addedCategory.name}" added successfully!`);
        setCategories(prev => [...prev, addedCategory]);
        setNewCategory({ name: '', shopId: '', picture: '' });
      })
      .catch((error) => {
        console.error('Error adding category:', error);
        if (error.response && error.response.data && error.response.data.message) {
          alert(`Failed to add category: ${error.response.data.message}`);
        } else {
          alert('Failed to add category: Unknown error occurred.');
        }
      });
  };

  const handleAddProduct = () => {
    if (!newProduct.name || !newProduct.description || !newProduct.price_czk || !newProduct.stock || !newProduct.categoryId) {
      alert('Please select a category and fill in all the fields for the product.');
      return;
    }
    // Ensure selected categoryId exists in the list of categories
    const selectedCategory = categories.find((category) => category.id === newProduct.categoryId);
    if (!selectedCategory) {
      alert('Please select a valid category from the list.');
      return;
    }
    // Validate Price (must be a valid decimal number greater than 0)
    if (isNaN(newProduct.price_czk) || parseFloat(newProduct.price_czk) <= 0) {
      alert('Please enter a valid price greater than 0.');
      return;
    }
    // Validate Stock (must be a non-negative integer)
    if (isNaN(newProduct.stock) || parseInt(newProduct.stock, 10) < 0) {
      alert('Stock must be a non-negative integer.');
      return;
    }
    // Validate Picture URL (if provided, must be a valid URL)
    if (newProduct.picture && !/^https?:\/\/[\w-]+(\.[\w-]+)+[/#?]?.*$/.test(newProduct.picture)) {
      alert('Please provide a valid URL for the picture.');
      return;
    }
    // Set default value for Picture if not provided
    if (!newProduct.picture) {
      newProduct.picture = null; // Применить значение по умолчанию из модели
    }

    addProduct(newProduct.categoryId, { name: newProduct.name, description: newProduct.description, price_czk: newProduct.price_czk, stock: newProduct.stock, picture: newProduct.picture })
      .then((addedProduct) => {
        alert(`Product "${addedProduct.name}" added successfully!`);
        setProducts(prev => [...prev, addedProduct]);
        setNewProduct({ name: '', description: '', price_czk: '', stock: '', picture: '', categoryId: '' });
      })
      .catch((error) => {
        console.error('Error adding category:', error);
        if (error.response && error.response.data && error.response.data.message) {
          alert(`Failed to add category: ${error.response.data.message}`);
        } else {
          alert('Failed to add category: Unknown error occurred.');
        }
      });
    };

  const fetchCategoriesInShop = async (shopId) => {
    if (!shopId) {
        setCategoriesInShop([]); // Сброс категорий, если магазин не выбран
        return;
    }

    fetchShopCategories(shopId)
      .then(data => setCategoriesInShop(data))
      .catch(error => console.error('Error fetching categories:', error));
  };

  const fetchStatistics = () => {
    fetch(`https://leviatyan.online/orders/statistics/monthly?year=${selectedYear}&month=${selectedMonth}`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        setStatistics({
          totalRevenue: data.totalRevenue,
          totalPurchases: data.totalPurchases,
          revenueByDay: processToNumbers(data.revenueByDay),
          usersByDay: processToNumbers(data.usersByDay),
          salesByStore: processSalesByStore(data.salesByStore),
        });
      })
      .catch((error) => console.error('Error fetching statistics:', error));
  };

  // Преобразование revenueByDay и usersByDay в числа
  const processToNumbers = (data) => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = Number(data[key]) || 0;
      return acc;
    }, {});
  };

  // Преобразование salesByStore
  const processSalesByStore = (data) => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = Number(data[key]) || 0;
      return acc;
    }, {});
  };
  
  const renderAddingTabContent = () => {
        switch (activeAddTab) {
            case 'AddShop':
                return (
                    <div className="adding-block">
                        {/* Форма для добавления магазина */}
                        <div className="add-block">
                            <div className="add-form">
                                <h3>Add New Shop</h3>
                                <div className="input-data">
                                    <input
                                        placeholder="Shop Name"
                                        type="text"
                                        name="shop-name"
                                        value={newShop.name}
                                        onChange={(e) => setNewShop({...newShop, name: e.target.value})}
                                    />
                                </div>

                                <div className="input-data textarea">
                                    <textarea
                                        placeholder="Description"
                                        value={newShop.description}
                                        onChange={e => setNewShop({...newShop, description: e.target.value})}
                                    />
                                </div>

                                <div className="input-data">
                                    <input
                                        type="number"
                                        placeholder="ICO"
                                        value={newShop.ico}
                                        onChange={(e) => setNewShop({...newShop, ico: e.target.value})}
                                    />
                                </div>
                                <div className="input-data">
                                    <input
                                        type="text"
                                        placeholder="Owner Name"
                                        value={newShop.ownerName}
                                        onChange={(e) => setNewShop({...newShop, ownerName: e.target.value})}
                                    />
                                </div>
                                <div className="input-data">
                                    <input
                                        type="email"
                                        placeholder="Owner Email"
                                        value={newShop.ownerEmail}
                                        onChange={(e) => setNewShop({...newShop, ownerEmail: e.target.value})}
                                    />
                                </div>
                                <div className="input-data">
                                    <input
                                        type="text"
                                        placeholder="Owner Phone"
                                        value={newShop.ownerPhone}
                                        onChange={(e) => setNewShop({...newShop, ownerPhone: e.target.value})}
                                    />
                                </div>
                                <div className="input-data">
                                    <input
                                        type="text"
                                        placeholder="Picture URL"
                                        value={newShop.picture}
                                        onChange={(e) => setNewShop({...newShop, picture: e.target.value})}
                                    />
                                </div>

                                <div className="status-toggle">
                                    <div className="status-button-header">Shop status</div>
                                    <button
                                        className={newShop.status === 'active' ? 'active' : ''}
                                        onClick={() => setNewShop({...newShop, status: 'active'})}
                                    >
                                        Active
                                    </button>
                                    <button
                                        className={newShop.status === 'disabled' ? 'active' : ''}
                                        onClick={() => setNewShop({...newShop, status: 'disabled'})}
                                    >
                                        Disabled
                                    </button>
                                </div>
                                <button onClick={handleAddShop}>Add Shop</button>
                            </div>
                        </div>
                        {/* Список существующих магазинов */}
                        <div className="list">
                            <div className="list-tittle">
                                <h3>Existing Shops</h3>
                            </div>
                            <div className="list-items">
                                {shops.map((shop) => (
                                    <div key={shop.id} className="item">
                                        <img src={shop.picture} alt={shop.name} />
                                        <h4>{shop.name}</h4>
                                        <p>Owner: {shop.ownerName}</p>
                                        <p className="email">Owners contacts: {shop.ownerEmail}, {shop.ownerPhone}</p>
                                        <p>Status: {shop.status}</p>
                                        <button
                                            className={shop.status === 'active' ? 'disable-button' : 'enable-button'}
                                            onClick={() => handleChangeShopStatus(shop.id, shop.status === 'active' ? 'disabled' : 'active')}
                                        >
                                            {shop.status === 'active' ? 'Disable' : 'Enable'}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );

            case 'AddCategory':
                return (
                    <div>
                        {/* Форма для добавления категории */}
                        <div className="adding-block">
                            <div className="add-block">
                                <div className="add-form">
                                    <h3>Add New Category</h3>
                                    <div className="input-data">
                                        <select
                                            value={newCategory.shopId}
                                            onChange={(e) => setNewCategory({...newCategory, shopId: e.target.value})}>
                                            <option value="">Select Shop</option>
                                            {shops.map((shop) => (
                                                <option key={shop.id} value={shop.id}>
                                                    {shop.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            placeholder="Category Name"
                                            value={newCategory.name}
                                            onChange={(e) => setNewCategory({...newCategory, name: e.target.value})}
                                        />
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            placeholder="Picture URL"
                                            value={newCategory.picture}
                                            onChange={(e) => setNewCategory({...newCategory, picture: e.target.value})}
                                        />
                                    </div>
                                    <button onClick={handleAddCategory}>Add Category</button>
                                </div>
                            </div>
                                {/* Список существующих категорий */}
                                <div className="list">
                                    <div className="list-tittle">
                                        <h3>Existing Categories</h3>
                                    </div>
                                    <div className="list-items">
                                        {categories.map((category) => (
                                            <div key={category.id} className="item">
                                                <img src={category.picture} alt={category.name} />
                                                <h4>{category.name}</h4>
                                                <p>For shop: {shops.find((sho) => sho.id === category.shopId)?.name || 'Unknown'}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                        </div>
                    </div>
                );

            case 'AddProduct':
            return (
                  <div>
                      {/* Форма для добавления продукта */}
                      <div className="adding-block">
                          <div className="add-block">
                              <div className="add-form">
                                  <h3>Add New Product</h3>
                                  {/* Выбор магазина */}
                                  <div className="input-data">
                                      <select
                                          value={selectedShop}
                                          onChange={(e) => {
                                              const shopId = e.target.value;
                                              setSelectedShop(shopId);
                                              fetchCategoriesInShop(shopId); // Запрос категорий по выбранному магазину
                                          }}>
                                          <option value="">Select Shop</option>
                                          {shops.map((shop) => (
                                              <option key={shop.id} value={shop.id}>
                                                  {shop.name}
                                              </option>
                                          ))}
                                      </select>
                                  </div>
                                  {/* Выбор категории в магазине */}
                                  <div className="input-data">
                                      <select
                                          value={newProduct.categoryId}
                                          onChange={(e) => setNewProduct({...newProduct, categoryId: e.target.value})}
                                      >
                                          <option value="">Select Category</option>
                                          {categoriesInShop.map((categoryInShop) => (
                                              <option key={categoryInShop.id} value={categoryInShop.id}>
                                                  {categoryInShop.name}
                                              </option>
                                          ))}
                                      </select>
                                  </div>
                                  <div className="input-data">
                                      <input
                                          type="text"
                                          placeholder="Product Name"
                                          value={newProduct.name}
                                          onChange={(e) => setNewProduct({...newProduct, name: e.target.value})}
                                      />
                                  </div>
                                  <div className="input-data textarea">
                                      <textarea
                                          placeholder="Description"
                                          value={newProduct.description}
                                          onChange={(e) => setNewProduct({...newProduct, description: e.target.value})}
                                      />
                                  </div>
                                  <div className="input-data">
                                      <input
                                          type="number"
                                          placeholder="Price (CZK)"
                                          value={newProduct.price_czk}
                                          onChange={(e) => setNewProduct({...newProduct, price_czk: e.target.value})}
                                      />
                                  </div>
                                  <div className="input-data">
                                      <input
                                          type="number"
                                          placeholder="Stock"
                                          value={newProduct.stock}
                                          onChange={(e) => setNewProduct({...newProduct, stock: e.target.value})}
                                      />
                                  </div>
                                  <div className="input-data">
                                      <input
                                          type="text"
                                          placeholder="Picture URL"
                                          value={newProduct.picture}
                                          onChange={(e) => setNewProduct({...newProduct, picture: e.target.value})}
                                      />
                                  </div>
                                  <button onClick={handleAddProduct}>Add Product</button>
                              </div>
                          </div>
                          {/* Список существующих продуктов */}
                          <div className="list">
                              <div className="list-tittle">
                                <h3>Existing Products</h3>
                              </div>
                              <div className="list-items">
                                  {products.map((product) => (
                                      <div key={product.id} className="item">
                                          <img src={product.picture} alt={product.name} />
                                          <h4>{product.name}</h4>
                                          <p>Category: {categories.find((cat) => cat.id === product.categoryId)?.name || 'Unknown'}</p>
                                          <p>Price: {CommaFormatted(product.price_czk.toString())} CZK</p>
                                          <p>Stock: {CommaFormatted(product.stock.toString())}</p>
                                      </div>
                                  ))}
                              </div>
                          </div>
                      </div>
                  </div>
            );
            default:
                return null;
        }
    };

  const renderTabContent = () => {
        switch (activeTab) {

            case 'Products':
                return (
                    <div className="products-content">
                        <div className="tittle">
                            <h2>Manage Shops, Categories, and Products</h2>
                        </div>
                        {/* Разделение на магазины.категории.товары */}
                        <nav className="adding-navbar">
                            <button onClick={() => setActiveAddTab('AddShop')} className={activeAddTab === 'AddShop' ? 'active' : ''}>
                                Shop
                            </button>
                            <button onClick={() => setActiveAddTab('AddCategory')} className={activeAddTab === 'AddCategory' ? 'active' : ''}>
                                Category
                            </button>
                            <button onClick={() => setActiveAddTab('AddProduct')} className={activeAddTab === 'AddProduct' ? 'active' : ''}>
                                Product
                            </button>
                        </nav>
                        <div className="adding-content">{renderAddingTabContent()}</div>
                    </div>
                );

            case 'Orders':
                return (
                    <div className="orders-container">
                        <h2>All Orders</h2>
                        <div className="order-header">
                            <p>Order ID</p>
                            <p>Date</p>
                            <p>Status</p>
                            <p>Address</p>
                            <p>User email</p>
                            <p>Products</p>
                            <p>Total</p>
                        </div>
                        {orders && orders.length > 0 ? (
                            orders.map((order) => (
                                <div key={order.id} className="order-container">
                                    <div className="order-card">
                                        <span id="order-id">{order.id}</span>
                                        <span>{order.createdAt ? new Date(order.createdAt).toLocaleDateString() : 'Unknown Date'}</span>
                                        <span>{order.status}</span>
                                        <span>{order.deliveryAddress}</span>
                                        <span>{order.User.email}</span>

                                        <span className="order-products">
                                            {order.items && order.items.length > 0 ? (order.items.map(
                                                    (item) => (
                                                        <ul key={item.id} className="product-order-item">
                                                            <li id="product-name">{item.Product ? item.Product.name : 'Unknown Product'}</li>
                                                            <li id="product-price">{item.quantity} x {CommaFormatted(item.Product ? item.Product.price_czk : '0'.toString())} CZK</li>
                                                        </ul>
                                                    ))
                                            ) : (
                                                <p>No products in this order.</p>
                                            )}
                                        </span>
                                        <span>{CommaFormatted(order.total.toString())} CZK</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No orders found.</p>
                        )}
                    </div>
                );

            case 'Users':
                return (
                    <div className="users-container">
                        <div className="users-container-header">
                            <h2>All Users</h2>
                        </div>
                        <div className="users-list">
                            {allUsers.map((user) => (
                                <div key={user.id} className="user-item">
                                    <div className="user-info">
                                        <p>{user.isAdmin ? 'Admin' : 'User'}</p>
                                        <p className="user-name">{user.name}</p>
                                        <p className="email">{user.email}</p>
                                    </div>
                                    <div className="copy-email-button">
                                        <button className="copy-button" onClick={() => copyToClipboard(user.email)}>Copy Email</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                );
      case 'Statistics':
        return (
          <div className="statistics-block">
              <div className="statistics-header">
                <h2>Statistics</h2>
              </div>
            {/* Выбор месяца и года */}
              <div className="statistics-controls">
                  <div className="input-data">
                      <input
                          type="number"
                          value={selectedYear}
                          onChange={(e) => setSelectedYear(e.target.value)}
                          min="2000"
                          max={new Date().getFullYear()}
                      />
                  </div>
                  <div className="month-selector">
                      <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                          {Array.from({length: 12}, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                  {new Date(0, i).toLocaleString('en-US', {month: 'long'})}
                              </option>
                          ))}
                      </select>
                  </div>
                  <div className="load-statistic-button">
                    <button onClick={fetchStatistics}>Load Statistics</button>
                    <button onClick={() => downloadCSV(orders)}>Export to CSV</button>
                  </div>
              </div>

              {/* Отображение статистики */}
              <div className="statistics-content">
                  {statistics.totalPurchases > 0 ? (
                      <>
                          <p>Total Purchases: {statistics.totalPurchases}</p>
                          <p>Total Revenue: {CommaFormatted(statistics.totalRevenue || 0)} CZK</p>
                <div className="charts">
                  <div className="chart-container">
                    <RevenueChart data={statistics.revenueByDay} />
                  </div>
                  <div className="chart-container">
                    <NewUsersChart data={statistics.usersByDay} />
                  </div>
                  <div className="chart-container" id="sales-by-store">
                    <StoreSalesChart data={statistics.salesByStore} />
                  </div>
                </div>

              </>
            ) : (
              <p>No data available for the selected period.</p>
            )}

            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Content been added into buffer.');
      })
      .catch(err => {
        console.error('Ошибка при копировании:', err);
        alert('Не удалось скопировать номер заказа.');
      });
    };

  return (
        <div className="profile-page">
            <div className="headers">
                <Header/>
                <UnderHeader/>
            </div>
            <div className="user-profile">
                {!user ? (
                    <p>Please log in to view your profile.</p>
                ) : user.isAdmin ? (
                    <div className="admin-page">
                        <div className="admin-page-tittle">
                            <h1>Admin Panel</h1>
                        </div>
                        <div className="admin-info">
                            <nav className="admin-navbar">
                                <button onClick={() => setActiveTab('Products')} className={activeTab === 'Products' ? 'active' : ''}>
                                    Manage
                                </button>
                                <button onClick={() => setActiveTab('Orders')} className={activeTab === 'Orders' ? 'active' : ''}>
                                    Orders
                                </button>
                                <button onClick={() => setActiveTab('Users')} className={activeTab === 'Users' ? 'active' : ''}>
                                    Users
                                </button>
                                <button onClick={() => setActiveTab('Statistics')} className={activeTab === 'Statistics' ? 'active' : ''}>
                                    Statistics
                                </button>
                            </nav>
                            <div className="admin-content">
                                {renderTabContent()}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="user-info">
                        <div className="account-info">
                            <h1>User Profile</h1>
                            <p>Name: {user.name}</p>
                            <p className="email">Email: {user.email}</p>
                        </div>
                        <div className="user-purchases">
                            <h2>My Purchases</h2>
                            {purchases.length > 0 ? (
                                <div className="purchases-container">
                                    <div className="purchases-header">
                                        <p id="order-id">Order ID</p>
                                        <p>Products</p>
                                        <p>Total</p>
                                        <p>Status</p>
                                    </div>
                                    {purchases.map(
                                        (purchase) => (
                                            <div key={purchase.id} className="purchase-card">
                                                <div className="purchase-id">
                                                    <p>{purchase.id}</p>
                                                    <button onClick={() => copyToClipboard(purchase.id)}>Copy ID</button>
                                                </div>
                                                <div className="purchase-content">
                                                    <ul>
                                                        {purchase.items.map(
                                                            (item, index) => (
                                                                <li key={index}>
                                                                    {item.name} (x{item.quantity})
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="purchase-total">
                                                    <p>{CommaFormatted(purchase.total.toString())} CZK</p>
                                                </div>
                                                <div className="purchase-status">
                                                    <p>{purchase.status}</p>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : (
                                <p>No purchases yet.</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Footer/>
            <BottomArrow/>
        </div>
    );
};

export default ProfilePage;