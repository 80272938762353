// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import CartPage from './pages/CartPage';
import PaymentSuccess from './pages/payment/PaymentSuccess';
import PaymentFailure from './pages/payment/PaymentFailure';
import ArtPage from './pages/ArtPage';
import ProfilePage from './pages/ProfilePage';
import CategoryPage from './pages/CategoryPage';
import ProductsInCategoryPage from './pages/ProductsInCategoryPage';
import { CartProvider } from './context/CartContext'; // Импортируем CartProvider

function App() {
  return (
    <CartProvider> {/* Оборачиваем приложение в CartProvider */}
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/shops/:shopId/categories" element={<CategoryPage />} />
          <Route path="/shops/:shopId/categories/:categoryId/products" element={<ProductsInCategoryPage />} />  
          <Route path="/user-cart" element={<CartPage />} />

          <Route path="/art" element={<ArtPage/>} />

          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/payment/success/:orderId?" element={<PaymentSuccess/>} />
          <Route path="/payment/failure" element={<PaymentFailure/>} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;