// src/components/ShopCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './ShopCard.css';

const ShopCard = ({ image, alt, title, link,
  description, ownerName, ownerPhone, ownerEmail }) => { // исправлено
 return (
   <div className="shop-card">
     <Link to={link}>
       <div className="shop-card-inner">
         <img src={image} alt={alt} className="shop-image" />
         <div className="shop-title">
             <h3>{title}</h3>
         </div>
           <div className="shop-info">
             <p className="description">{description}</p>
             <p className="owner-info">By: {ownerName}</p>
             <p className="owner-info email">Email: {ownerEmail}</p>
             <p className="owner-info">Tel: {ownerPhone}</p>
           </div>
       </div>
     </Link>
   </div>
 );
};


export default ShopCard;