// frontend/src/pages/CartPage.js
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { FaPlus, FaMinus, FaTrash } from 'react-icons/fa';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UnderHeader from '../components/UnderHeader';
import { updateCartItemQuantity, removeItemFromCart } from '../utils/cartUtils';
import { fetchAddress, saveAddress } from '../utils/deliveryUtils';
import { CartContext } from '../context/CartContext'; // Import CartContext
import './CartPage.css';
import BottomArrow from "../components/BottomArrow";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cartId, setCartId] = useState(null);
  const [errors, setErrors] = useState({});
  const { updateCartQuantity } = useContext(CartContext); // Use CartContext to update cart quantity

  // Delivery address state
  const [address, setAddress] = useState({ street: '', city: '', postalCode: '', region: '' });
  const [isEditing, setIsEditing] = useState(true);

  const validateAddress = () => {
    const newErrors = {};
    if (!address.street) newErrors.street = "Street is required";
    if (!address.city) newErrors.city = "City is required";
    if (!address.postalCode) newErrors.postalCode = "Postal Code is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // UseMemo for address validation
  const isAddressValid = useMemo(() => validateAddress(), [address]);

  const toggleEditSaveAddress = () => {
    if (isEditing) {
      saveAddress(address);
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
    
  };

  const calculateTotalPrice = (items) => {
    if (!items || items.length === 0) return;
    const total = items.reduce((sum, item) => sum + parseFloat(item.price_czk) * item.quantityInCart, 0);
    setTotalPrice(total);
  };

  function CommaFormatted(amount) {
    if (typeof amount !== 'string') {
      amount = amount.toFixed(2);
    }

    const delimiter = ",";
    const [integerPart, decimalPart = ''] = amount.split('.');

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  }

  const fetchCart = () => {
    fetch('https://leviatyan.online/cart/view', {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.items) {
          setCartItems(data.items || []);
          calculateTotalPrice(data.items || []);
          setCartId(data.cartId);

          // Update cart quantity in context
          const totalQuantity = data.items.reduce((sum, item) => sum + item.quantityInCart, 0);
          updateCartQuantity(totalQuantity);
        }
      })
      .catch(error => console.error('Error fetching cart:', error));
  };

  const loadAddress = async () => {
    await fetchAddress((data) => {
      if (data && data.street) {
        setAddress(data); // Auto-fill with fetched address
        setIsEditing(false); // Not in edit mode if address exists
      } else {
        setIsEditing(true); // Enable editing if no address is found
      }
    });
  };

  useEffect(() => {
    fetchCart();
    loadAddress();
  }, []);

  const handleIncrement = (productId) => {
    updateCartItemQuantity(productId, true, updateCartQuantity)
      .then(() => {
        fetchCart();
      })
      .catch((error) => { console.error('Error incrementing quantity:', error); alert(error.message); });
  };

  const handleDecrement = (productId) => {
    updateCartItemQuantity(productId, false, updateCartQuantity)
      .then(() => {
        fetchCart();
      })
      .catch(error => console.error('Error decrementing quantity:', error));
  };

  const handleRemove = (productId) => {
    removeItemFromCart(productId, updateCartQuantity)
      .then(() => {
        fetchCart();
      })
      .catch(error => console.error('Error removing item:', error));
  };

  const handleAddressChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  // Добавляем функцию для инициализации оплаты
  const initiatePayment = () => {
    if (!cartId) {  // Проверка, если cartId не определен
      console.error('cartId не определен');
      return;
    }

    // Проверка валидации адреса перед оплатой
    if (!validateAddress()) {
      alert('Please fill in the required delivery address fields before proceeding to payment.');
      return;
    }

    fetch('https://leviatyan.online/purchase/initiate', { // '/api/purchase/initiate'
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ cartId, totalPrice, address }),  // Передаем cartId как UUID
    })
      .then(response => response.json())
      .then(data => {
        if (data.redirectUrl) { // was data.paymentUrl
          window.location.href = data.redirectUrl;  // Перенаправляем пользователя на страницу оплаты // was data.paymentUrl
        } else {
          console.error('No redirect URL provided:', data);
        }
      })
      .catch(error => console.error('Ошибка при инициализации оплаты:', error));
  };


  return (
    <div className="service-page">
      <div className="headers">
        <Header />
        <UnderHeader title="Cart"></UnderHeader>
      </div>
      <div className="cart-content">
          {cartItems.length === 0 ? (
              <p>Cart is empty.</p>
          ) : (
        <div className="cart-items">
              <ul>
                {cartItems.map((item) => (
                    <li key={item.cartItemId} className="cart-item">
                    <div className="info-inner-blocks">
                      <div className="left-block">
                        <div>
                          <img className="item-img" src={item.picture} alt={item.name}/>
                        </div>
                        <div className="info-container">
                          <div className="item-info">
                            <strong className="item-title">{item.name}</strong>
                            <p className="item-description">{item.description}</p>
                          </div>

                          <div className="cart-item-actions">
                            <button
                                onClick={() => handleRemove(item.productId)}
                                className="remove-button"
                            >
                              <FaTrash/>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="right-block">
                        <div className="add-sub-but">
                          <button
                              onClick={() => handleDecrement(item.productId)}
                              disabled={item.quantityInCart === 1}
                              className="quantity-button"
                          >
                            <FaMinus/>
                          </button>
                          <span className="quantity">{item.quantityInCart}</span>
                          <button
                              onClick={() => handleIncrement(item.productId)}
                              className="quantity-button"
                          >
                            <FaPlus/>
                          </button>
                        </div>
                        <p className="item-total-price">Total: {CommaFormatted((item.price_czk * item.quantityInCart).toString())} CZK</p>
                      </div>
                    </div>
                    </li>
                ))}
              </ul>

        <div className="payment-block">
          <div className="address-form">
            <h3>Delivery Address</h3>
            <div className="input-data">
              <input
                  required
                  type="text"
                  name="street"
                  value={address.street}
                  onChange={handleAddressChange}
                  className="address-input"
                  // placeholder="Street and house number"
                  readOnly={!isEditing}
              />
              <div className="underline"></div>
              <label htmlFor="">{address.street ? (isEditing ? 'Street and house number' : '') : 'Street and house number'}</label>
            </div>
            <div className="input-data">
              <input
                  required
                  type="text"
                  name="city"
                  value={address.city}
                  onChange={handleAddressChange}
                  className="address-input"
                  readOnly={!isEditing}
              />
              <div className="underline"></div>
              <label htmlFor="">{address.city ? (isEditing ? 'City' : '') : 'City'}</label>
            </div>
            <div className="input-data">
              <input
                  required
                  type="text"
                  name="postalCode"
                  value={address.postalCode}
                  onChange={handleAddressChange}
                  className="address-input"
                  readOnly={!isEditing}
              />
              <div className="underline"></div>
              <label htmlFor="">{address.postalCode ? (isEditing ? 'Postal Code' : '') : 'Postal Code'}</label>
            </div>
            <div className="input-data">
              <input
                  required
                  type="text"
                  name="region"
                  value={address.region}
                  onChange={handleAddressChange}
                  className="address-input"
                  readOnly={!isEditing}
              />
              <div className="underline"></div>
              <label htmlFor="">{address.region ? (isEditing ? 'Region (optional)' : '') : 'Region (optional)'}</label>
            </div>
            <button onClick={toggleEditSaveAddress} className="address-button">
              {isEditing ? 'Save Address' : 'Edit Address'}
            </button>
          </div>

          <div className="total-price">
            Total: {CommaFormatted(totalPrice.toString())} CZK
          </div>

          {cartItems.length > 0 && (
              <button
                  onClick={initiatePayment}
                  className={`payment-button ${(!isAddressValid || isEditing) ? 'disabled-button' : ''}`}
                  disabled={!isAddressValid || isEditing} // Заблокировать кнопку, если адрес некорректен
              >
                  Pay
              </button>
          )}
        </div>
      </div>
)}
    </div>
      <Footer/>
      <BottomArrow/>
    </div>
  );

};

export default CartPage;