// src/pages/RustPage.js
import React, {useEffect} from 'react';
import Header from '../components/Header';
import CategoryCard from '../components/CategoryCard';
import UnderHeader from '../components/UnderHeader';
import Footer from '../components/Footer';

function ArtPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы до начала
  }, []);
  return (
    <div className="service-page">
      <Header/>
      <UnderHeader 
      title="Art">

      </UnderHeader>
      <div className="services">
      <CategoryCard
  image="/img/handmade_card.jpg"
  title="Painings"
  description="Lorem Ipsum"
  link="/art/paintings"
/>

      
      </div>
      <Footer/>
    </div>
    
  );
}

export default ArtPage;