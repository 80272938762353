// src/components/Header.js
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../context/CartContext'; // Импортируем CartContext
import { fetchCartQuantity } from '../utils/cartUtils'; // Import fetchCartQuantity

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const { cartQuantity, updateCartQuantity } = useContext(CartContext); // Используем контекст для количества товаров
  const navigate = useNavigate();

  useEffect(() => {
    // Проверяем, авторизован ли пользователь
    fetch('https://leviatyan.online/api/user', {
        method: 'GET',
        credentials: 'include', // Этот параметр важен для отправки куков
    })
    .then((response) => response.json())
    .then((data) => {
        if (data.email) {
            setIsAuthenticated(true);
            setUser(data);
            initializeCartQuantity(); // Обновляем количество товаров при загрузке
        } 
        else {
            setIsAuthenticated(false);
        }
    })
    .catch(() => setIsAuthenticated(false));
}, []);

  const initializeCartQuantity = async () => {
    try {
      const quantity = await fetchCartQuantity();
      updateCartQuantity(quantity);
    } catch (error) {
      console.error('Error initializing cart quantity:', error);
      updateCartQuantity(0); // Ensure the context is reset if fetching fails
    }
  };

  const handleLogout = () => {
    fetch('https://leviatyan.online/auth/logout', {
        method: 'POST',
        credentials: 'include' // Ensure cookies are sent with the request
    })
    .then(() => {
        setIsAuthenticated(false);
        setUser(null);
        navigate("/");
        initializeCartQuantity();
    })
    .catch((error) => {
        console.error('Error logging out:', error);
    });
  };

  const handleLogin = () => {
    window.location.href = 'https://leviatyan.online/auth/login';
  };

  return (
    <header className="header">
      <div className="logo">
        <a href="/" className="logo-link">
          <img src="/art_trans.png" alt="MyLogo" className="logo-image" />
        </a>
      </div>
        <div className="auth-buttons">
          <Link to="/user-cart" className="cart-icon">
            <img
              src='/img/cart_icon.ico'
              height="30px"
              alt="cart"
              style={{cursor: 'pointer'}}
            />
            {cartQuantity > 0 && (
              <span className="cart-quantity">{cartQuantity}</span>
            )}
          </Link>
          {isAuthenticated ? (
              <>
          <span>
            <Link to="/profile" state={{user}}>
              <img src={user?.picture} alt="User Profile" className='profile-photo'/>
            </Link>
          </span>
          <button className='login_button' onClick={handleLogout}>Log Out</button>
              </>
          ) : (
              <button className='login_button' onClick={handleLogin}>Log in</button>
          )}
        </div>
    </header>
  );
};

export default Header;