// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p id="contacts">Contacts:</p>
        <p className="email">Email: <a href="mailto:pschtchnk@gmail.com">artplace@gmail.com</a></p>
        <p>Telegram: <a href="https://t.me/bufferka_shop" target="_blank" rel="noopener noreferrer">@art_place</a></p>
      </div>
    </footer>
  );
};

export default Footer;
