// src/pages/PaymentSuccess.js
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './PaymentPages.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BottomArrow from "../../components/BottomArrow";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { orderId } = useParams(); // Optional parameter

  useEffect(() => {
    if (!orderId) {
      console.log('No order ID provided, but payment is successful.');
    } else {
      console.log(`Payment successful for order ID: ${orderId}`);
    }
  }, [orderId]);

  const handleGoToGallery = () => {
    navigate('/'); // Redirect user to the gallery page
  };

  const handleGoToProfile = () => {
    navigate('/profile'); // Redirect user to their profile page
  };

  return (
    <div className="payment-success">
      <Header />

      <div className="content">
        <div className="info">
          <h1>🎉 Thank You for Your Purchase! 🎉</h1>
          <p>Your order ID: <strong>{orderId}</strong></p>
          <p>We're excited to deliver your amazing ArtPlace items!</p>
          <div className="success-actions">
            <button onClick={handleGoToGallery} className="btn-gallery">
              Explore More Art
            </button>
            <button onClick={handleGoToProfile} className="btn-profile">
              Go to My Profile
            </button>
          </div>
        </div>
      </div>
      <Footer />
      <BottomArrow />
    </div>
  );
};

export default PaymentSuccess;